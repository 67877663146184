import { hoverHeight } from 'components/FilmPage/FilmPageHeadDesktop/styled/index';
import styled from 'styled-components';

export default styled.header`
  height: ${hoverHeight};
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  color: ${({ theme }) => theme.colors.primary.light};
`;
