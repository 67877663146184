import AwardsPressSection from 'components/FilmPage/AwardsPressSection';
import AwardsPressSectionMobile from 'components/FilmPage/AwardsPressSection/Mobile';
import FilmDescription from 'components/FilmPage/FilmDescription';
import FilmPageHeadDesktop from 'components/FilmPage/FilmPageHeadDesktop';
import FilmPageHeadMobile from 'components/FilmPage/FilmPageHeadMobile';
import LinkSection from 'components/FilmPage/LinkSection';
import FilmPageOpenedContent from 'components/FilmPage/styled/FilmPageOpenedContent';
import FilmPageWrapper from 'components/FilmPage/styled/FilmPageWrapper';
import TrailerSection from 'components/FilmPage/TrailerSection';
import { FilmPageContext } from 'context/FilmPageContext';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import HR from 'components/HR';
import useDesktopMode from 'utils/hooks/useDesktopMode';

interface Props {
  page: FilmPageType;
  commons: CommonsType;
  index: number;
}

const FilmPage = ({ page, commons, index }: Props): JSX.Element => {
  const { state } = useContext(FilmPageContext);
  const isVisible = () => !state.isOpen || state.currentFilm === index;
  const { isDesktopMode } = useDesktopMode();

  return (
    <>
      {isVisible() && (
        <FilmPageWrapper
          style={{ zIndex: state.isHovered === index ? '2' : '1' }}
        >
          <section id={`header-content${index}`}>
            {isDesktopMode && (
              <FilmPageHeadDesktop
                page={page}
                commons={commons}
                index={index}
              />
            )}
            {!isDesktopMode && (
              <FilmPageHeadMobile page={page} commons={commons} index={index} />
            )}
          </section>
          {state.isOpen && (
            <FilmPageOpenedContent id={'opened-content'}>
              <FilmDescription page={page} commons={commons} index={index} />
              <HR />
              {isDesktopMode && (
                <AwardsPressSection data={page} commons={commons} />
              )}
              {!isDesktopMode && (
                <AwardsPressSectionMobile data={page} commons={commons} />
              )}
              <HR />
              {page.media.trailer && (
                <TrailerSection data={page} commons={commons} />
              )}
              <HR />
              <LinkSection index={index} />
            </FilmPageOpenedContent>
          )}
        </FilmPageWrapper>
      )}
    </>
  );
};

export default FilmPage;
