import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.hr`
  height: 10vh;
  border: none;
  ${mediaTablet(css`
    height: 20vh;
  `)}
  ${mediaTabletAndPortrait(css`
    height: 10vh;
  `)}
`;
