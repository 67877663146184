import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';
import { MadeBonVoyage, NexaBlack } from 'utils/styles/typography';

export default styled.span`
  ${MadeBonVoyage};
  white-space: pre-line;
  font-size: 10vw;
  line-height: 9vw;
  ${mediaDesktop(css`
    font-size: 4.645vw;
    line-height: 3.9177vw;
    margin: 0 6.927vw;
  `)}
  b {
    ${NexaBlack};
  }
`;
