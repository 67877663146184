import { hoverHeight } from 'components/FilmPage/FilmPageHeadDesktop/styled/index';
import styled from 'styled-components';

export const CurtainContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

export const CurtainPart = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary.light};
`;

export const CurtainWrapper = styled.div`
  height: ${hoverHeight};
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
