import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';
import { Arapey } from 'utils/styles/typography';

export default styled.p`
  ${Arapey};
  width: 100%;
  font-size: 5vw;
  line-height: 4vw;
  font-size: min(5vw, 24px);
  line-height: min(4vw, 20px);
  position: relative;
  top: 7.36vh;
  ${mediaTablet(css`
    font-size: 3vw;
    line-height: 3.875vw;
  `)}
  ${mediaTabletAndPortrait(css`
    font-size: 3.5vw;
    line-height: 4.5vw;
  `)};
  ${mediaDesktop(css`
    font-size: 1.3vw;
    line-height: 1.697vw;
    font-size: max(1.3vw, 20px);
    line-height: max(1.3vw, 24px);
  `)}
`;
