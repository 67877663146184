import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.section`
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  ${mediaDesktop(css`
    width: 86%;
  `)};
`;
