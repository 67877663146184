import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';
import { Arapey } from 'utils/styles/typography';

export default styled.span`
  ${Arapey};
  font-style: italic;
  text-align: center;
  font-size: 4vw;
  ${mediaTablet(css`
    font-size: 2vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 3vw;
  `)}
  ${mediaDesktop(css`
    font-size: 1vw;
    font-size: max(1vw, 20px);
  `)}
`;
