import { descriptionTop } from 'components/FilmPage/FilmDescription/CommonLayout/styled';
import { filmOpenedLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';
import { MadeBonVoyage, MadeBonVoyageThin } from 'utils/styles/typography';

export default styled.div`
  color: ${({ theme }) => theme.colors.primary.dark};
  ${MadeBonVoyageThin};
  b {
    ${MadeBonVoyage};
  }
  font-size: 25vw;
  line-height: 25vw;
  margin: 7vh 0 0 8%;
  ${mediaDesktop(`
    position: absolute;
    top: ${descriptionTop};
    left: ${filmOpenedLeft};
    margin: 0;
    font-size: 9.6vw;
    line-height: 9.6vw;
    font-size: max(9.6vw, 150px);
    line-height: max(9.6vw, 150px);
  `)};
`;
