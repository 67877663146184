import FilmTitle from 'components/FilmPage/FilmPageHeadDesktop/styled/FilmTitle';
import InnerTitleWrapper from 'components/FilmPage/FilmPageHeadDesktop/Title/styled/InnerTitleWrapper';
import {
  LabelLine,
  LabelSlideOut,
  LabelText,
  LabelWrapper,
} from 'components/FilmPage/FilmPageHeadDesktop/Title/styled/Label';
import TitleWrapper from 'components/FilmPage/FilmPageHeadDesktop/Title/styled/TitleWrapper';
import { createTitle } from 'components/FilmPage/FilmPageHeadDesktop/utils';
import { FilmPageContext } from 'context/FilmPageContext';
import React, { useContext, useEffect, useRef } from 'react';
import gsap, { Power3 } from 'gsap';
import useWindowSize from 'utils/hooks/useWindowSize';

interface Props {
  light?: boolean;
  className?: string;
  index: number;
  title: string;
  miscText: string;
  setHitboxSize?: (width: number, height: number) => void;
}

const Title = ({
  light = false,
  className = null,
  index,
  title,
  miscText,
  setHitboxSize,
}: Props) => {
  const label = useRef(null);
  const titleRef = useRef(null);
  const innerWrapper = useRef(null);
  const labelSlideOut = useRef(null);
  const windowSize = useWindowSize();
  const { state } = useContext(FilmPageContext);

  if (setHitboxSize) {
    useEffect(() => {
      const { width, height } = titleRef.current.getBoundingClientRect();
      setHitboxSize(width, height);
    }, [windowSize.height, windowSize.width]);
  }

  useEffect(() => {
    if (state.isHovered === index) {
      gsap.to(label.current, {
        duration: 0.5,
        ease: Power3.easeIn,
        width: '17.7vw',
      });
    } else {
      gsap.to(label.current, {
        duration: 0.5,
        ease: Power3.easeIn,
        width: '5.7vw',
      });
    }
  }, [state.isHovered]);

  useEffect(() => {
    if (state.preloaderExited) {
      gsap
        .timeline({
          delay: 0.1,
          scrollTrigger: {
            trigger: titleRef.current,
          },
        })
        .to(innerWrapper.current, {
          y: 0,
          duration: 0.7,
          ease: 'power3.inout',
        })
        .to(labelSlideOut.current, {
          x: 0,
          opacity: 1,
          duration: 0.3,
          ease: 'power1.out',
        });
    }
  }, [state.preloaderExited]);

  useEffect(() => {
    if (state.currentFilm === index) {
      gsap.to(innerWrapper.current, {
        y: '100%',
        duration: 0.25,
        ease: 'power2.out',
      });
    }
  }, [state.currentFilm]);

  return (
    <TitleWrapper className={className} index={index} ref={titleRef}>
      <InnerTitleWrapper ref={innerWrapper}>
        <FilmTitle dangerouslySetInnerHTML={createTitle(title)} />
        <LabelWrapper>
          <LabelSlideOut ref={labelSlideOut}>
            <LabelText>{('0' + (index + 1)).slice(-2)}</LabelText>
            <LabelLine light={light} ref={label} />
            <LabelText>{miscText}</LabelText>
          </LabelSlideOut>
        </LabelWrapper>
      </InnerTitleWrapper>
    </TitleWrapper>
  );
};

export default Title;
