import QuoteAuthor from 'components/FilmPage/FilmDescription/Quote/styled/QuoteAuthor';
import QuoteContainer from 'components/FilmPage/FilmDescription/Quote/styled/QuoteContainer';
import QuoteLabel from 'components/FilmPage/FilmDescription/Quote/styled/QuoteLabel';
import QuoteLine from 'components/FilmPage/FilmDescription/Quote/styled/QuoteLine';
import QuoteWrapper from 'components/FilmPage/FilmDescription/Quote/styled/QuoteWrapper';
import Line from 'components/Icons/Line';
import React from 'react';
import { FilmQuoteType } from 'types/filmPage';

interface Props {
  data: FilmQuoteType;
  className?: string;
}

const Quote = ({ data, className }: Props) => {
  return (
    <QuoteContainer className={className}>
      <QuoteWrapper>{data.quote.toUpperCase()}</QuoteWrapper>
      <QuoteLabel>
        <QuoteLine>
          <Line />
        </QuoteLine>
        <QuoteAuthor>{data.author}</QuoteAuthor>
      </QuoteLabel>
    </QuoteContainer>
  );
};

export default Quote;
