import { createTitle } from 'components/FilmPage/FilmPageHeadDesktop/utils';
import ArrowShortRight from 'components/FilmPage/LinkSection/Link/styled/ArrowShortRight';
import LinkText from 'components/FilmPage/LinkSection/Link/styled/LinkText';
import LinkWrapper from 'components/FilmPage/LinkSection/Link/styled/LinkWrapper';
import CallBackFunc from 'interface/callbackFunc';
import React from 'react';
import ArrowShort from 'components/Icons/ArrowShort';
import { motion } from 'framer-motion';

interface Props {
  text: string;
  right?: boolean;
  onClick?: CallBackFunc<void, void>;
}

const variants = {
  left: {
    hover: {
      x: '-50%',
    },
  },
  right: {
    hover: {
      x: '+50%',
    },
  },
};

const Link = ({ right = false, text, onClick }: Props) => {
  return (
    <LinkWrapper
      onClick={onClick}
      whileHover={'hover'}
      role="button"
      aria-label={right ? 'next film' : 'previous film'}
    >
      {!right && (
        <motion.div variants={variants.left}>
          <ArrowShort />
        </motion.div>
      )}
      <LinkText dangerouslySetInnerHTML={createTitle(text)} />
      {right && (
        <motion.div variants={variants.right}>
          <ArrowShortRight />
        </motion.div>
      )}
    </LinkWrapper>
  );
};

export default Link;
