import styled, { css } from 'styled-components';
import media from 'utils/responsive';
import { MadeBonVoyage, NexaBlack } from 'utils/styles/typography';

export default styled.h1`
  ${MadeBonVoyage};
  margin: 1rem 0;
  white-space: pre-line;
  font-size: 12vw;
  line-height: 10.5vw;
  b {
    ${NexaBlack};
  }
  ${media.tablet(css`
    font-size: 10vw;
    line-height: 10vw;
  `)}
`;
