import ArrowButton from 'components/FilmPage/AwardsPressSection/Mobile/styled/ArrowButton';
import MobileFooter from 'components/FilmPage/AwardsPressSection/Mobile/styled/MobileFooter';
import SliderSection from 'components/FilmPage/AwardsPressSection/Mobile/styled/SliderSection';
import { useSlider } from 'components/FilmPage/AwardsPressSection/Slider';
import SliderItem from 'components/FilmPage/AwardsPressSection/Slider/SliderItem';
import InnerWrapper from 'components/FilmPage/AwardsPressSection/Slider/styled/InnerWrapper';
import ItemSection from 'components/FilmPage/AwardsPressSection/Slider/styled/ItemSection';
import SliderWrapper from 'components/FilmPage/AwardsPressSection/Slider/styled/SliderWrapper';
import ArrowShortRight from 'components/FilmPage/LinkSection/Link/styled/ArrowShortRight';
import ArrowShort from 'components/Icons/ArrowShort';
import React, { useRef, useState, useEffect } from 'react';
import { AwardsPressType } from 'types/filmPage';

type Props = {
  items: Array<AwardsPressType>;
};

const MobileSlider = ({ items }: Props) => {
  const [isActive, setIsActive] = useState(true);
  const slider = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const onUserScroll = () => {
    const scrollPercentage = Math.round(
      (window.scrollY /
        (document.documentElement.offsetHeight - window.innerHeight)) *
        100
    );
    if (scrollPercentage >= 72 && isActive) {
      setIsActive(false);
      scrollRight();
      setTimeout(scrollLeft, 1500);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onUserScroll);

    return () => {
      window.removeEventListener('scroll', onUserScroll);
    };
  }, [isActive]);

  const {
    scrollLeft,
    scrollRight,
    onScroll,
    isLeftScrollActive,
    isRightScrollActive,
    onPointerUp,
    onPointerMove,
    onPointerDown,
  } = useSlider({ slider, wrapper });

  return (
    <SliderSection>
      <SliderWrapper
        ref={slider}
        onScroll={onScroll}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerUp}
      >
        <InnerWrapper ref={wrapper}>
          <ItemSection>
            {items
              .map((i, index) => <SliderItem key={index} data={i} />)
              .reverse()}
          </ItemSection>
        </InnerWrapper>
      </SliderWrapper>
      <MobileFooter>
        <ArrowButton
          whileHover={{ x: isLeftScrollActive && '-10%' }}
          isActive={isLeftScrollActive}
          onClick={scrollLeft}
        >
          <ArrowShort />
        </ArrowButton>
        <ArrowButton
          whileHover={{ x: isRightScrollActive && '+10%' }}
          isActive={isRightScrollActive}
          onClick={scrollRight}
        >
          <ArrowShortRight />
        </ArrowButton>
      </MobileFooter>
    </SliderSection>
  );
};

export default MobileSlider;
