import { metadataLeft } from 'components/FilmPage/styled';
import React from 'react';
import styled from 'styled-components';
import { MetadataBold, MetadataItalic } from 'utils/styles/typography';

const Wrapper = styled.div`
  position: relative;
  left: ${metadataLeft};
`;

const DoubleLineMetadata = ({ children }) => (
  <Wrapper>
    <MetadataBold light>{children[0]}</MetadataBold>
    <MetadataItalic light>{children[1]}</MetadataItalic>
  </Wrapper>
);

export default DoubleLineMetadata;
