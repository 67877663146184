import { motion } from 'framer-motion';
import styled from 'styled-components';

export default styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  -webkit-tap-highlight-color: transparent;
  svg {
    width: 100%;
    height: 100%;
  }
  transition: color 0.2s ease-out;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'auto')};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black : theme.colors.lightGray3};
`;
