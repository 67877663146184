import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';
import { NexaBlack } from 'utils/styles/typography';

const style = css`
  ${NexaBlack};
  color: #bdbdbd;
  font-size: 3.7vw;
  line-height: 3.7vw;
  font-size: min(3.7vw, 16px);
  line-height: min(3.7vw, 16px);
  justify-self: flex-end;
  position: absolute;
  top: 80%;
  ${mediaTablet(css`
    font-size: 1.4vw;
    line-height: 1.4vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 1.8vw;
    line-height: 1.8vw;
  `)};
  ${mediaDesktop(css`
    top: 86%;
    font-size: 0.729vw;
    line-height: 0.729vw;
    font-size: max(0.729vw, 12px);
    line-height: max(0.729vw, 12px);
  `)};
  border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray3}`};
  padding-top: 5%;
  width: 100%;
`;

export const FooterLinkPlaceholder = styled.span`
  ${style}
`;

export default styled.a`
  ${style};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
