import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 52%;
  left: 98%;
  display: block;
  overflow: hidden;
`;

export const WhereSlideout = styled.div`
  transform: translateX(-100%);
`;
