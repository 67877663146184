import FilmImage from 'components/FilmPage/FilmDescription/FilmImage';
import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled(FilmImage)`
  height: auto;
  margin-left: 5%;
  width: 80%;
  ${mediaTablet(`
    left: 7.5%;
    width: 50%;
  `)}
  ${mediaTabletAndPortrait(`
    width: 70%;
  `)}
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    left: ${metadataLeft};
    width: 30.73%;
    z-index: 2;
  `)}
`;
