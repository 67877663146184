import styled from 'styled-components';

export default styled.span`
  position: fixed;
  height: 5vw;
  width: 5vw;
  border-radius: 5vw;
  background-color: white;
  z-index: 998;
  mix-blend-mode: difference;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;
