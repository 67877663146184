import Arrow from 'components/Icons/Arrow';
import styled from 'styled-components';

export default styled(Arrow)`
  height: auto;
  width: 11vh;
  position: absolute;
  bottom: 15.8%;
  right: 7%;
  transform: rotate(90deg);
  transform-origin: right center;
  cursor: pointer;
`;
