import styled from 'styled-components';

export default styled.div`
  min-height: 15px;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'auto')};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black : theme.colors.lightGray3};
  transition: color 0.2s ease-out;
`;
