import { hoverHeight } from 'components/FilmPage/FilmPageHeadDesktop/styled';
import {
  Author,
  AuthorsWrapper,
} from 'components/FilmPage/FilmPageHeadDesktop/styled/Authors';
import ContentWrapper from 'components/FilmPage/FilmPageHeadDesktop/styled/ContentWrapper';
import {
  CurtainContent,
  CurtainPart,
  CurtainWrapper,
} from 'components/FilmPage/FilmPageHeadDesktop/styled/Curtain';
import FilmTitleOpened, {
  Text,
} from 'components/FilmPage/FilmPageHeadDesktop/styled/FilmTitleOpened';
import HiddenContent from 'components/FilmPage/FilmPageHeadDesktop/styled/HiddenContent';
import Hitbox from 'components/FilmPage/FilmPageHeadDesktop/styled/Hitbox';
import OpenedContent from 'components/FilmPage/FilmPageHeadDesktop/styled/OpenedContent';
import RelativeWrapper from 'components/FilmPage/FilmPageHeadDesktop/styled/RelativeWrapper';
import VideoBackground from 'components/FilmPage/FilmPageHeadDesktop/styled/VideoBackground';
import Title from 'components/FilmPage/FilmPageHeadDesktop/Title';
import { createTitle } from 'components/FilmPage/FilmPageHeadDesktop/utils';
import HeaderLogo from 'components/FilmPage/HeaderLogo';
import useOpenClose from 'components/FilmPage/hooks/useOpenClose';
import MenuVideo from 'components/Menu/MenuContentDesktop/styled/MenuVideo';
import SlideOut from 'components/SlideOut';
import { FilmPageContext } from 'context/FilmPageContext';
import gsap, { Power3 } from 'gsap';
import jump from 'jump.js';
import React, { useContext, useRef, useState } from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import { parseNewlines } from 'utils/stringUtils';
import { MetadataBold, MetadataItalic } from 'utils/styles/typography';
import {
  ACTIONS,
  CATEGORIES,
  getAction,
  getLabel,
  LABEL,
  Tracking,
} from 'utils/tracking';
import ScrollDownArrow from './styled/ScrollDownArrow';
import FeaturedAwards from './styled/FeaturedAwards';

interface Props {
  page: FilmPageType;
  commons: CommonsType;
  index: number;
}

const vars = {
  duration: 0.5,
};

const FilmPageHeadDesktop = ({ page, commons, index }: Props) => {
  const { state, setState } = useContext(FilmPageContext);
  const hiddenContent = useRef(null);
  const curtain = useRef(null);
  const relativeWrapper = useRef(null);
  const wrapper = useRef(null);
  const video = useRef(null);
  const curtainTop = useRef(null);
  const curtainContentTop = useRef(null);
  const curtainBot = useRef(null);
  const curtainContentBot = useRef(null);
  const [hitboxSize, setHitboxSize] = useState({ height: 0, width: 0 });

  const setHitboxSizeCalllback = (width: number, height: number): void => {
    if (process.browser && wrapper.current) {
      setHitboxSize({
        height: height / wrapper.current.getBoundingClientRect().height,
        width: width / window.innerWidth,
      });
    }
  };

  const translateY = (el, value) => {
    gsap.to(el.current, {
      ...vars,
      y: value,
    });
  };

  const translateYSet = (el, value) => {
    gsap.set(el.current, {
      y: value,
    });
  };

  const closeCurtain = () => {
    translateY(curtainTop, 0);
    translateY(curtainContentTop, 0);
    translateY(curtainBot, 0);
    translateY(curtainContentBot, 0);
  };

  const closeCurtainSet = () => {
    translateYSet(curtainTop, 0);
    translateYSet(curtainContentTop, 0);
    translateYSet(curtainBot, 0);
    translateYSet(curtainContentBot, 0);
  };

  const openCurtain = () => {
    translateY(curtainTop, '-100%');
    translateY(curtainContentTop, '100%');
    translateY(curtainBot, '100%');
    translateY(curtainContentBot, '-100%');
  };

  const openCurtainSet = () => {
    translateYSet(curtainTop, '-100%');
    translateYSet(curtainContentTop, '100%');
    translateYSet(curtainBot, '100%');
    translateYSet(curtainContentBot, '-100%');
  };

  const openPage = () => {
    const { top, height } = wrapper.current.getBoundingClientRect();
    let offset = 0;
    let moveCenterDuration = vars.duration;
    if (process.browser) {
      offset =
        window.innerHeight / 2 -
        (top + height / 2) +
        wrapper.current.getBoundingClientRect().height / 2;
      //slower animation if the distance between scroll and center is big
      moveCenterDuration = gsap.utils.mapRange(
        0,
        window.innerHeight / 2,
        0,
        1,
        Math.abs(offset - height / 2)
      );
    }
    //set pos to be the same, but fixed
    gsap.set(wrapper.current, {
      top: wrapper.current.getBoundingClientRect().top,
      position: 'fixed',
    });
    //move wrapper to the center of screen
    gsap.to(wrapper.current, {
      ease: Power3.easeInOut,
      duration: moveCenterDuration,
      delay: 0.2,
      top: `+=${offset}`,
      translateY: '-50%',
    });
    //resize cover
    gsap.to([hiddenContent.current, curtain.current], {
      ...vars,
      delay: 0.2,
      ease: Power3.easeInOut,
      height: '100vh',
      onComplete: () => {
        setState({ isOpen: true, currentFilm: index });
        gsap.set(wrapper.current, {
          position: 'absolute',
          top: 0,
          translateY: 0,
        });
        gsap.set(relativeWrapper.current, {
          height: '100vh',
        });
        location.hash = page.urlTitle;

        Tracking.event({
          category: CATEGORIES.PageLanding,
          action: getAction(page.urlTitle),
          label: getLabel(page.urlTitle),
        });
      },
    });
  };

  const openPageNoAnimation = () => {
    gsap.set(video.current, {
      scale: 1,
    });
    openCurtainSet();
    gsap.set(wrapper.current, {
      position: 'absolute',
      top: 0,
      translateY: 0,
    });
    gsap.set(
      [hiddenContent.current, curtain.current, relativeWrapper.current],
      {
        height: '100vh',
      }
    );
  };

  const closePage = () => {
    gsap.set(wrapper.current, {
      position: 'relative',
    });
    gsap.set(
      [hiddenContent.current, curtain.current, relativeWrapper.current],
      {
        height: hoverHeight,
        onComplete: () => {
          closeCurtainSet();
        },
      }
    );
  };

  const onMouseEnter = () => {
    setState({ isHovered: index });
    gsap.to(video.current, {
      ...vars,
      scale: 1,
    });
    openCurtain();
  };

  const onMouseLeave = () => {
    if (state.currentFilm !== index) {
      setState({ isHovered: -1 });
      gsap.to(video.current, {
        ...vars,
        scale: 1.2,
      });
      closeCurtain();
    }
  };

  useOpenClose(openPage, openPageNoAnimation, closePage, index);

  const onLogoClick = () => {
    location.hash = 'home';

    Tracking.event({
      category: CATEGORIES.PageLanding,
      action: ACTIONS.Logo,
      label: LABEL.Logo,
    });
  };

  const onArrowClick = () => {
    const el = document.getElementById('opened-content');
    if (el) {
      jump(el, { duration: distance => distance * 0.5 });
    }
  };

  return (
    <RelativeWrapper ref={relativeWrapper}>
      {!state.isOpen && (
        <Hitbox
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={() => setState({ isHovered: index, currentFilm: index })}
          index={index}
          size={hitboxSize}
          role="button"
          aria-label="View film details"
        />
      )}
      <ContentWrapper ref={wrapper}>
        <HiddenContent ref={hiddenContent}>
          <VideoBackground ref={video} loop muted playsInline autoPlay>
            {state.videoQuality === 'high' && (
              <source src={page.media.head.high.url} type="video/mp4" />
            )}
            {state.videoQuality !== 'high' && (
              <source src={page.media.head.medium.url} type="video/mp4" />
            )}
          </VideoBackground>
          {!state.isOpen && (
            <Title
              index={index}
              title={page.title}
              miscText={commons.landingItemLabel.more}
              setHitboxSize={setHitboxSizeCalllback}
              light
            />
          )}
          {state.isOpen && (
            <OpenedContent>
              <HeaderLogo onClick={onLogoClick} />
              <ScrollDownArrow
                role="button"
                aria-label="scroll-down"
                onClick={onArrowClick}
              />
              <FilmTitleOpened>
                <SlideOut>
                  <Text dangerouslySetInnerHTML={createTitle(page.title)} />
                </SlideOut>
              </FilmTitleOpened>

              <AuthorsWrapper>
                {page.featuredAwards.length > 0 && (
                  <FeaturedAwards
                    images={page.featuredAwards}
                    label={commons.featuredAwards}
                  />
                )}
                {page.authors.map(author => (
                  <Author key={author.name}>
                    <SlideOut>
                      <MetadataBold light>
                        {parseNewlines(author.name).toUpperCase()}
                      </MetadataBold>
                    </SlideOut>
                    <SlideOut>
                      <MetadataItalic light>{author.title}</MetadataItalic>
                    </SlideOut>
                  </Author>
                ))}
              </AuthorsWrapper>
            </OpenedContent>
          )}
        </HiddenContent>
        <CurtainWrapper ref={curtain}>
          <CurtainPart ref={curtainTop}>
            {!state.isOpen && (
              <CurtainContent ref={curtainContentTop}>
                <Title
                  className={'top'}
                  index={index}
                  title={page.title}
                  miscText={commons.landingItemLabel.more}
                />
              </CurtainContent>
            )}
          </CurtainPart>
          <CurtainPart ref={curtainBot}>
            {!state.isOpen && (
              <CurtainContent ref={curtainContentBot}>
                <Title
                  className={'bottom'}
                  index={index}
                  title={page.title}
                  miscText={commons.landingItemLabel.more}
                />
              </CurtainContent>
            )}
          </CurtainPart>
        </CurtainWrapper>
      </ContentWrapper>
    </RelativeWrapper>
  );
};

export default FilmPageHeadDesktop;
