import {
  DescriptionBold,
  DescriptionItalic,
} from 'components/FilmPage/FilmDescription/CommonLayout/FilmDescription/styled/DescriptionText';
import DescriptionWrapper from 'components/FilmPage/FilmDescription/CommonLayout/FilmDescription/styled/DescriptionWrapper';
import React from 'react';
import { FilmDescriptionType } from 'types/filmPage';

const FilmDescription = ({ bold, normal }: FilmDescriptionType) => (
  <DescriptionWrapper>
    <DescriptionBold>{bold.toUpperCase()}</DescriptionBold>
    <DescriptionItalic>{normal}</DescriptionItalic>
  </DescriptionWrapper>
);

export default FilmDescription;
