import styled, { css } from 'styled-components';
import { mediaDesktop, mediaTablet } from 'utils/responsive';

export default styled.img`
  height: 6vh;
  ${mediaTablet(css`
    height: 7vh;
  `)}
  ${mediaDesktop(css`
    height: 9vh;
  `)}
  width: auto;
  margin-right: 3vw;
`;
