import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { mediaTablet, mediaTabletAndPortrait } from 'utils/responsive';
import { Arapey } from 'utils/styles/typography';

export default styled(motion.span)`
  ${Arapey};
  color: white;
  font-size: 5vw;
  position: absolute;
  top: 160%;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
  z-index: 999;
  ${mediaTablet(css`
    font-size: 2.77865vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 5vw;
  `)};
`;
