import ComeToDaddy from 'components/FilmPage/FilmDescription/Films/ComeToDaddy';
import ComeToDaddyLayout from 'components/FilmPage/FilmDescription/Films/ComeToDaddy/styled/ComeToDaddyLayout';
import GetDuked from 'components/FilmPage/FilmDescription/Films/GetDuked';
import GetDukedLayout from 'components/FilmPage/FilmDescription/Films/GetDuked/styled/GetDukedLayout';
import IBlameSociety from 'components/FilmPage/FilmDescription/Films/IBlameSociety';
import IBSLayout from 'components/FilmPage/FilmDescription/Films/IBlameSociety/styled/IBSLayout';
import DefaultFilm from 'components/FilmPage/FilmDescription/Films/DefaultFilm'
import NineDays from 'components/FilmPage/FilmDescription/Films/NineDays';
import NineDaysLayout from 'components/FilmPage/FilmDescription/Films/NineDays/styled/NineDaysLayout';
import React from 'react';
import DefaultLayout from './styled/DefaultLayout';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';

interface Props {
  page: FilmPageType;
  commons: CommonsType;
  index: number;
}

const FilmDescription = ({ page, commons, index }: Props) => {
  //TODO: rewrite this shit. It's really, really bad. Not at all flexible 
  const getContainer = () => {
    switch (page.urlTitle) {
      case 'get-duked':
        return (
          <GetDukedLayout page={page} commons={commons}>
            <GetDuked page={page} />
          </GetDukedLayout>
        );
      case 'come-to-daddy':
        return (
          <ComeToDaddyLayout page={page} commons={commons}>
            <ComeToDaddy page={page} />
          </ComeToDaddyLayout>
        );
      case 'nine-days':
        return (
          <NineDaysLayout page={page} commons={commons}>
            <NineDays page={page} />
          </NineDaysLayout>
        );
      case 'i-blame-society':
        return (
          <IBSLayout page={page} commons={commons}>
            <IBlameSociety page={page} />
          </IBSLayout>
        );
      default:
        return (
          <DefaultLayout variant={page.media.body.length > 0 && page.quotes.length > 0 ? 'large' : 'small'} page={page} commons={commons} >
            <DefaultFilm page={page} />
          </DefaultLayout>
        );
      // return null;
    }
  };

  return <>{getContainer()}</>;
};

export default FilmDescription;
