import MobileSlider from 'components/FilmPage/AwardsPressSection/Mobile/MobileSlider';
import MobileHeader from 'components/FilmPage/AwardsPressSection/Mobile/styled/MobileHeader';
import MobileImage from 'components/FilmPage/AwardsPressSection/Mobile/styled/MobileImage';
import MobilePressHeader from 'components/FilmPage/AwardsPressSection/Mobile/styled/MobilePressHeader';
import AwardsPressWrapper from 'components/FilmPage/AwardsPressSection/styled/AwardsPressWrapper';
import { SECTIONS } from 'constants/sections';
import React from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import Image from 'next/image';

interface Props {
  data: FilmPageType;
  commons: CommonsType;
}

const AwardsPressSectionMobile = ({ data, commons }: Props) => {
  return (
    <AwardsPressWrapper>
      {data.media.awardsPress ? (
        <MobileImage>
          <Image
            src={data.media.awardsPress.url}
            width={1920}
            height={691}
            layout={'responsive'}
            quality={100}
          />
          )
        </MobileImage>
      ) : null}
      {data.awards.length ? (
        <>
          <MobileHeader id={SECTIONS.AWARDS_PRESS}>
            {commons.awardsPressHeader.awards}
          </MobileHeader>
          <MobileSlider items={data.awards} />
        </>
      ) : null}
      <MobilePressHeader>{commons.awardsPressHeader.press}</MobilePressHeader>
      <MobileSlider items={data.press} />
    </AwardsPressWrapper>
  );
};

export default AwardsPressSectionMobile;
