const NetworkSpeed = require('network-speed');
const testNetworkSpeed = new NetworkSpeed();

export async function getNetworkDownloadSpeed(): Promise<number> {
  const baseUrl = 'https://eu.httpbin.org/stream-bytes/500000';
  const fileSizeInBytes = 500000;
  const { mbps } = await testNetworkSpeed.checkDownloadSpeed(
    baseUrl,
    fileSizeInBytes
  );
  return Number.parseFloat(mbps);
}
