import styled, { css } from 'styled-components';
import { mediaTablet } from 'utils/responsive';
import { Arapey } from 'utils/styles/typography';

export default styled.p`
  ${Arapey};
  font-style: italic;
  margin: 0;
  font-size: 6vw;
  &:first-child {
    margin-top: 2rem;
  }
  ${mediaTablet(css`
    font-size: 3vw;
    &:first-child {
      margin-top: 3rem;
    }
  `)}
`;
