import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  width: 60vw;
  height: 30vh;
  min-height: 15rem;
  position: relative;
  ${mediaTablet(css`
    width: 30vw;
    height: 60vh;
    min-height: 25rem;
  `)}
  ${mediaTabletAndPortrait(css`
    width: 37vw;
    height: 35vh;
    min-height: 0;
  `)}
  ${mediaDesktop(css`
    width: 14.42vw;
    height: 14.42vw;
    min-height: 300px;
    min-width: 250px;
  `)}
`;
