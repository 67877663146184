import styled from 'styled-components';
import { NexaBlack } from 'utils/styles/typography';

export default styled.h2`
  ${NexaBlack};
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.primary.dark};
  -webkit-text-stroke-width: 2px;
  color: ${({ theme, isHover }) =>
    isHover ? theme.colors.primary.dark : 'transparent'};
  font-size: 7.29vw;
  line-height: 7.29vw;
  margin: 0;
  transition: color 0.1s ease-out;
`;
