import { filmOpenedLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { H2 } from 'utils/styles/typography';

export default styled.div`
  position: absolute;
  bottom: 15.8%;
  left: ${filmOpenedLeft};
`;

export const Text = styled(H2)`
  position: relative;
  display: block;
  padding: 2vh 0;
`;
