import React from 'react';
import useDesktopMode from 'utils/hooks/useDesktopMode';

interface Props {
  className?: string;
}

const ArrowShort = ({ className }: Props) => {
  const { isDesktopMode } = useDesktopMode();

  return (
    <>
      {isDesktopMode ? (
        <svg
          width="71"
          height="9"
          viewBox="0 0 71 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M9.17745 -1.51493e-05L5.36621e-08 4.49999L9.17745 9L7.60869 5.45061L71 5.45061L71 3.54937L7.60869 3.54937L9.17745 -1.51493e-05Z"
            fill="currentColor"
            shapeRendering="geometricPrecision"
          />
        </svg>
      ) : (
        <svg
          width="64"
          height="12"
          viewBox="0 0 64 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M12.652 0.000200422L7.14666e-08 5.99326L12.652 11.9863L10.8763 7.0291L64 7.0291L64 4.95743L10.8763 4.95743L12.652 0.000200422Z"
            fill="currentColor"
            shapeRendering="geometricPrecision"
          />
        </svg>
      )}
    </>
  );
};

export default ArrowShort;
