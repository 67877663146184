import { useSlider } from 'components/FilmPage/AwardsPressSection/Slider';
import ArrowButton from 'components/FilmPage/AwardsPressSection/styled/Footer/ArrowButton';
import ArrowsWrapper from 'components/FilmPage/AwardsPressSection/styled/Footer/ArrowsWrapper';
import FooterWrapper from 'components/FilmPage/AwardsPressSection/styled/Footer/FooterWrapper';
import HoverLine from 'components/FilmPage/AwardsPressSection/HoverLine';
import InnerWrapper from 'components/FilmPage/AwardsPressSection/Slider/styled/InnerWrapper';
import ItemSection from 'components/FilmPage/AwardsPressSection/Slider/styled/ItemSection';
import SliderWrapper from 'components/FilmPage/AwardsPressSection/Slider/styled/SliderWrapper';
import SliderItem from 'components/FilmPage/AwardsPressSection/Slider/SliderItem';
import AwardsPressWrapper from 'components/FilmPage/AwardsPressSection/styled/AwardsPressWrapper';
import HeaderItem from 'components/FilmPage/AwardsPressSection/styled/HeaderItem';
import HeaderText from 'components/FilmPage/AwardsPressSection/styled/HeaderText';
import HeaderWrapper from 'components/FilmPage/AwardsPressSection/styled/HeaderWrapper';
import ArrowLong from 'components/Icons/ArrowLong';
import ArrowShort from 'components/Icons/ArrowShort';
import { SECTIONS } from 'constants/sections';
import React, { useEffect, useRef, useState } from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import Image from 'next/image';
import { v4 as uuid } from 'uuid';

interface Props {
  data: FilmPageType;
  commons: CommonsType;
}

const AwardsPressSection = ({ data, commons }: Props) => {
  const [isPress, setIsPress] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isHeaderInteractive = data.awards.length !== 0;
  const slider = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const pressSection = useRef<HTMLDivElement>(null);
  const awardSection = useRef<HTMLDivElement>(null);

  const onAwardsClick = () => {
    slider.current.scrollTo({ behavior: 'smooth', left: 0 });
  };

  const onPressClick = () => {
    const x = pressSection.current.offsetLeft - wrapper.current.offsetLeft;
    slider.current.scrollTo({ behavior: 'smooth', left: x });
  };

  const onUserScroll = () => {
    const scrollPercentage = Math.round(
      (window.scrollY /
        (document.documentElement.offsetHeight - window.innerHeight)) *
        100
    );
    if (scrollPercentage >= 72 && !isScrolled) {
      setIsScrolled(true);
      setIsPress(false);
      onAwardsClick();
    }
  };

  useEffect(() => {
    onPressClick();
    setIsPress(true);
    window.addEventListener('scroll', onUserScroll);

    return () => {
      window.removeEventListener('scroll', onUserScroll);
    };
  }, []);

  const onScrollCallback = (x: number) => {
    const sectionX =
      pressSection.current.offsetLeft - wrapper.current.offsetLeft;
    if ((x < sectionX || x === 0) && isScrolled) {
      if (isPress) {
        setIsPress(false);
      }
    } else {
      if (!isPress) {
        setIsPress(true);
      }
    }
  };

  const {
    scrollLeft,
    scrollRight,
    onScroll,
    isLeftScrollActive,
    isRightScrollActive,
    onPointerUp,
    onPointerMove,
    onPointerDown,
  } = useSlider({ slider, wrapper, onScrollCallback });

  return (
    <AwardsPressWrapper>
      {data.media.awardsPress ? (
        <Image
          src={data.media.awardsPress.url}
          width={1920}
          height={691}
          layout={'responsive'}
          quality={100}
          priority
        />
      ) : (
        <div style={{ paddingTop: '3rem' }} />
      )}
      <HeaderWrapper id={SECTIONS.AWARDS_PRESS}>
        {!!data.awards.length && (
          <HeaderItem interactive={isHeaderInteractive} onClick={onAwardsClick}>
            <HeaderText isHover={!isPress}>
              {commons.awardsPressHeader.awards}
            </HeaderText>
            <HoverLine isHover={!isPress} />
          </HeaderItem>
        )}
        <HeaderItem interactive={isHeaderInteractive} onClick={onPressClick}>
          <HeaderText isHover={isPress}>
            {commons.awardsPressHeader.press}
          </HeaderText>
          <HoverLine isHover={isPress} />
        </HeaderItem>
      </HeaderWrapper>
      <SliderWrapper
        ref={slider}
        onScroll={onScroll}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerUp}
      >
        <InnerWrapper ref={wrapper}>
          <ItemSection ref={awardSection}>
            {data.awards
              .map(i => <SliderItem key={uuid()} data={i} />)
              .reverse()}
          </ItemSection>
          <ItemSection ref={pressSection}>
            {data.press
              .map(i => <SliderItem key={uuid()} data={i} />)
              .reverse()}
          </ItemSection>
        </InnerWrapper>
      </SliderWrapper>
      <FooterWrapper>
        <ArrowsWrapper>
          <ArrowButton onClick={scrollLeft} isActive={isLeftScrollActive}>
            <ArrowShort />
          </ArrowButton>
          <ArrowButton onClick={scrollRight} isActive={isRightScrollActive}>
            <ArrowLong />
          </ArrowButton>
        </ArrowsWrapper>
      </FooterWrapper>
    </AwardsPressWrapper>
  );
};

export default AwardsPressSection;
