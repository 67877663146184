import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  margin: 8vh 0 8vh ${metadataLeft};
  & > * {
    margin-bottom: 8vh !important;
  }
  ${mediaTablet(`
    margin: 20vh 0 20vh ${metadataLeft};
    & > * {
      margin-bottom: 10vh !important;
    }
  `)};
  ${mediaTabletAndPortrait(`
    margin: 12vh 0 12vh ${metadataLeft};
    & > * {
      margin-bottom: 6vh !important;
    }
  `)};
  ${mediaDesktop(`
    margin: 0;
    & > * {
      margin-bottom: 0 !important;
    }
  `)}
`;
