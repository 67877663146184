import styled, { css } from 'styled-components';
import { mediaDesktop, mediaTablet } from 'utils/responsive';

export default styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.52vw;
  width: 50vw;
  ${mediaTablet(css`
    width: 32vw;
  `)};
  ${mediaDesktop(css`
    margin-left: 2.52vw;
    width: 5.78125vw;
    width: max(5.78125vw, 100px);
  `)}
`;
