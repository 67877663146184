import Quote from 'components/FilmPage/FilmDescription/Quote';
import { descriptionLeft } from 'components/FilmPage/FilmDescription/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export const Quote1 = styled(Quote)`
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    top: 225vh;
    left: ${descriptionLeft};
  `)};
`;

export const Quote2 = styled(Quote)`
  font-size: 8vw;
  line-height: 7.1vw;
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    bottom: 0;
    font-size: 6.4vh;
    line-height: 5.68vh;
    left: ${descriptionLeft};
  `)};
`;
