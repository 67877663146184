import ArrowWrapper from 'components/FilmPage/FilmDescription/ImageLink/styled/ArrowWrapper';
import Label from 'components/FilmPage/FilmDescription/ImageLink/styled/Label';
import LabelContainer from 'components/FilmPage/FilmDescription/ImageLink/styled/LabelContainer';
import LabelWrapper from 'components/FilmPage/FilmDescription/ImageLink/styled/LabelWrapper';
import LinkContainer from 'components/FilmPage/FilmDescription/ImageLink/styled/LinkContainer';
import LinkImage from 'components/FilmPage/FilmDescription/ImageLink/styled/LinkImage';
import LinkTitle from 'components/FilmPage/FilmDescription/ImageLink/styled/LinkTitle';
import React, { useEffect, useRef, useState } from 'react';
import { ImageLinkType } from 'types/filmPage';
import { openUrl } from 'utils/windowUtils';
import gsap from 'gsap';

interface Props {
  data: ImageLinkType;
  className?: string;
  img: {
    height: number;
    width: number;
  };
}

const ImageLink = ({ data, className, img }: Props) => {
  const [hover, setHover] = useState(false);
  const arrow = useRef(null);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  useEffect(() => {
    if (hover) {
      gsap.to(arrow.current, {
        x: '+=1vw',
      });
    } else {
      gsap.to(arrow.current, {
        x: 0,
      });
    }
  }, [hover]);

  return (
    <LinkContainer
      className={className}
      onClick={() => openUrl(data.url)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <LinkImage
        src={data.image.url}
        height={img.height}
        width={img.width}
        intro={'roll-down'}
      />
      <LabelContainer>
        <LinkTitle>{data.title}</LinkTitle>
        <LabelWrapper>
          <Label>{data.label}</Label>
          <ArrowWrapper>
            <svg
              width="100%"
              viewBox="0 0 111 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              ref={arrow}
            >
              <path
                d="M103.861 7L111 3.5L103.861 -3.12071e-07L104.863 2.89506L0.5 2.89506L0.5 4.10494L104.863 4.10494L103.861 7Z"
                fill="black"
              />
            </svg>
          </ArrowWrapper>
        </LabelWrapper>
      </LabelContainer>
    </LinkContainer>
  );
};

export default ImageLink;
