import SliderWrapper from 'components/FilmPage/AwardsPressSection/Slider/styled/SliderWrapper';
import CallBackFunc from 'interface/callbackFunc';
import React, { useRef, useState } from 'react';

type Props = {
  slider: React.MutableRefObject<HTMLDivElement>;
  wrapper: React.MutableRefObject<HTMLDivElement>;
  onScrollCallback?: CallBackFunc<number, void>;
};

export const useSlider = ({ slider, wrapper, onScrollCallback }: Props) => {
  const [isLeftScrollActive, setLeftScrollActive] = useState(false);
  const [isRightScrollActive, setRightScrollActive] = useState(true);

  const isDragged = useRef(false);
  const pos = useRef({ left: 0, x: 0 });

  const onPointerDown = e => {
    pos.current = { left: slider.current.scrollLeft, x: e.clientX };
    isDragged.current = true;
    wrapper.current.style.cursor = 'grabbing';
  };

  const onPointerMove = e => {
    if (isDragged.current) {
      const dx = e.clientX - pos.current.x;
      slider.current.scrollLeft = pos.current.left - dx;
    }
  };

  const onPointerUp = () => {
    isDragged.current = false;
    wrapper.current.style.cursor = 'grab';
  };

  const scrollRight = () => {
    slider.current.scrollBy({ behavior: 'smooth', left: innerWidth * 0.75 });
  };

  const scrollLeft = () => {
    slider.current.scrollBy({ behavior: 'smooth', left: innerWidth * -0.75 });
  };

  const onScroll = e => {
    e.preventDefault();
    const val = e.target.scrollLeft;
    const length =
      wrapper.current?.offsetWidth -
      e.target.offsetWidth +
      wrapper.current?.offsetLeft;
    const canScrollRight = (val >= 0 && val < length) || val < 0;
    const canScrollLeft = (val > 0 && val <= length) || val > length;
    setRightScrollActive(canScrollRight);
    setLeftScrollActive(canScrollLeft);
    if (onScrollCallback) {
      onScrollCallback(val);
    }
  };

  return {
    scrollLeft,
    scrollRight,
    onScroll,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    isLeftScrollActive,
    isRightScrollActive,
  };
};
