import styled from 'styled-components';

export default styled.div`
  & > * {
    margin-right: var(--gap, 7.29vw);
  }
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;
`;
