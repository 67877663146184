import LeftImage from 'components/FilmPage/FilmDescription/styled/LeftImage';
import RightImage from 'components/FilmPage/FilmDescription/styled/RightImage';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export const StyledLeftImage = styled(LeftImage)`
  ${mediaDesktop(`
    top: 155vh;
  `)};
`;

export const StyledRightImage = styled(RightImage)`
  ${mediaDesktop(`
    top: 115vh;
  `)};
`;
