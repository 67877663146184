import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  cursor: pointer;
  ${mediaDesktop(css`
    margin-bottom: 6%;
  `)};
`;
