import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 176px;
  height: 163px;
`;
