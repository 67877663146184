import {
  rollDown,
  rollRight,
} from 'components/FilmPage/FilmDescription/FilmImage/introAnimations';
import FilmImageWrapper from 'components/FilmPage/FilmDescription/FilmImage/styled/FilmImageWrapper';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';

interface Props {
  className?: string;
  src: string;
  width: number | string;
  height: number | string;
  intro: 'none' | 'roll-down' | 'roll-right';
}

const FilmImage = ({
  src,
  className,
  width,
  height,
  intro = 'none',
}: Props) => {
  const img = useRef(null);
  const wrapper = useRef(null);

  if (intro !== 'none') {
    useEffect(() => {
      intro === 'roll-down' ? rollDown(img, wrapper) : rollRight(img, wrapper);
    }, []);
  }

  return (
    <FilmImageWrapper className={className} ref={wrapper}>
      <div ref={img}>
        <Image
          src={src}
          width={width}
          height={height}
          quality={100}
          layout={'responsive'}
          priority={true}
        />
      </div>
    </FilmImageWrapper>
  );
};

export default FilmImage;
