import { filmOpenedLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';

export default styled.div`
  position: relative;
  left: ${filmOpenedLeft};
  display: flex;
  height: 0;
  top: -2.91rem;
  & > *:first-child {
    margin-right: 2vw;
  }
`;
