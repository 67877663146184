import gsap, { Power2 } from 'gsap';

const commonVars = {
  duration: 3,
  ease: Power2.easeOut,
  paused: true,
};

export const rollDown = (ref, trigger) => {
  gsap.from(ref.current, {
    ...commonVars,
    scale: 2,
    y: '-100%',
    transformOrigin: 'center center',
    scrollTrigger: {
      trigger: trigger.current,
    },
  });
};

export const rollRight = (ref, trigger) => {
  gsap.from(ref.current, {
    ...commonVars,
    x: '-100%',
    scale: 2,
    transformOrigin: 'center center',
    scrollTrigger: {
      trigger: trigger.current,
    },
  });
};
