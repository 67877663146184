import styled, { css } from 'styled-components';
import media, { mediaTablet } from 'utils/responsive';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  ${mediaTablet(css`
    margin-bottom: 2rem;
  `)};
`;
