import FeaturedAwardImage from 'components/FilmPage/FeaturedAwards/styled/FeaturedAwardImage';
import FeaturedAwardsWrapper from 'components/FilmPage/FeaturedAwards/styled/FeaturedAwardsWrapper';
import SlideOut from 'components/SlideOut';
import { SECTIONS } from 'constants/sections';
import jump from 'jump.js';
import React from 'react';
import CmsMediaType from 'types/cmsMedia';
import { MetadataItalic } from 'utils/styles/typography';
import { v4 } from 'uuid';

interface Props {
  images: Array<CmsMediaType>;
  label: string;
  className?: string;
}

const FeaturedAwards = ({ images, label, className }: Props) => {
  const onClick = () => {
    const el = document.getElementById(SECTIONS.AWARDS_PRESS);
    el && jump(el, { duration: 1000 });
  };

  return (
    <div className={className}>
      <SlideOut>
        <FeaturedAwardsWrapper onClick={onClick}>
          {images.map(img => (
            <FeaturedAwardImage key={v4()} src={img.url} alt={''} />
          ))}
        </FeaturedAwardsWrapper>
      </SlideOut>
      {/* <SlideOut>
        <MetadataItalic light>{label}</MetadataItalic>
      </SlideOut> */}
    </div>
  );
};

export default FeaturedAwards;
