import { motion } from 'framer-motion';
import styled from 'styled-components';
import { MadeBonVoyage, NexaBlack } from 'utils/styles/typography';

export default styled(motion.h1)`
  ${MadeBonVoyage};
  white-space: pre-line;
  font-size: 15vw;
  line-height: 15vw;
  position: relative;
  left: 6.4%;
  margin: 0;
  b {
    ${NexaBlack};
  }
`;
