import MobileHeader from 'components/FilmPage/AwardsPressSection/Mobile/styled/MobileHeader';
import styled, { css } from 'styled-components';
import { mediaTablet, mediaTabletAndPortrait } from 'utils/responsive';

export default styled(MobileHeader)`
  margin: 15vh 0 6vh 0;
  ${mediaTablet(css`
    margin: 27vh 0 13vh 0;
  `)}
  ${mediaTabletAndPortrait(css`
    margin: 15vh 0 6vh 0;
  `)}
`;
