import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.div`
  overflow: scroll;
  scrollbar-width: none;
  margin-top: 0;
  width: 100vw;
  user-select: none;
  ${mediaDesktop(css`
    margin-top: 20vh;
  `)}
`;
