import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 45%;
  left: 100%;
  transform: translateX(-100%);
  display: block;
  overflow: hidden;
`;

export const NoSlideout = styled.div`
  transform: translateX(100%);
`;
