import { FilmPageContext } from 'context/FilmPageContext';
import CallBackFunc from 'interface/callbackFunc';
import { useContext, useEffect } from 'react';

const useOpenClose = (
  open: CallBackFunc<void, void>,
  openNoAnimation: CallBackFunc<void, void>,
  close: CallBackFunc<void, void>,
  index: number
) => {
  const { state } = useContext(FilmPageContext);

  useEffect(() => {
    if (state.currentFilm === index) {
      if (state.isOpen) {
        openNoAnimation();
      } else {
        open();
      }
    } else {
      close();
    }
  }, [state.currentFilm]);
};

export default useOpenClose;
