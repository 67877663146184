import { getNetworkDownloadSpeed } from 'components/Preloader/checkDownloadSpeed';
import Where from 'components/Preloader/Icons/Where';
import Background from 'components/Preloader/styled/Background';
import CenterWrapper from 'components/Preloader/styled/CenterWrapper';
import Circle from 'components/Preloader/styled/Circle';
import DotWrapper from 'components/Preloader/styled/DotWrapper';
import LogoWrapper from 'components/Preloader/styled/LogoWrapper';
import NoWrapper, { NoSlideout } from 'components/Preloader/styled/NoWrapper';
import PreloaderWrapper from 'components/Preloader/styled/PreloaderWrapper';
import WhereWrapper, {
  WhereSlideout,
} from 'components/Preloader/styled/WhereWrapper';
import { FilmPageContext } from 'context/FilmPageContext';
import React, { useContext, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { FilmPageType } from 'types/filmPage';
import { isDesktop, videoQualityMinSpeed } from 'utils/platform';
import Dot from './Icons/Dot';
import No from './Icons/No';
import Preload from 'preload-it';

interface Props {
  data: Array<FilmPageType>;
}

const Preloader = ({ data }: Props) => {
  const wrapper = useRef(null);
  const circle = useRef(null);
  const blackBg = useRef(null);
  const dot = useRef(null);
  const no = useRef(null);
  const where = useRef(null);
  const { setState } = useContext(FilmPageContext);

  const animation = () => {
    gsap
      .timeline({
        onComplete: () => {
          setState({ preloaderExited: true });
        },
      })
      .to(circle.current, {
        scale: 0,
        transformOrigin: 'center center',
        duration: 0.7,
        ease: 'expo.in',
      })
      .to(blackBg.current, {
        opacity: 0,
        display: 'none',
        duration: 0.1,
        ease: 'power2.out',
      })
      .to(dot.current, {
        duration: 0.1,
        display: 'block',
        scale: 1,
        ease: 'power3.out',
      })
      .to([no.current, where.current], { translateX: 0, duration: 1 })
      .to(wrapper.current, {
        opacity: 0,
        delay: 1,
        duration: 0.1,
        display: 'none',
      })
      .set(document.documentElement, { overflow: 'visible' });
  };

  useEffect(() => {
    animation();
    if (process.browser) {
      const loader = new Preload();
      let items = data.map(page => page.media.head.medium.url);
      const desktop = isDesktop();
      getNetworkDownloadSpeed()
        .then(speed => {
          if (speed >= videoQualityMinSpeed.high) {
            items = data.map(page => page.media.head.high.url);
            setState({ videoQuality: 'high' });
          }
        })
        .catch(e => console.error(e))
        .finally(() => {
          if (desktop) {
            loader.fetch(items).catch(e => console.error(e));
          } else {
            loader
              .fetch(data.map(page => page.media.headMobile.source.url))
              .catch(e => console.error(e));
          }
        });
    }
  }, []);

  return (
    <PreloaderWrapper ref={wrapper}>
      <Background light />
      <Background ref={blackBg} />
      <CenterWrapper>
        <Circle fill={'none'}>
          <circle
            cx="50%"
            cy="50%"
            r="50%"
            fill={'currentColor'}
            ref={circle}
          />
        </Circle>
        <LogoWrapper>
          <DotWrapper ref={dot}>
            <Dot />
          </DotWrapper>
          <NoWrapper>
            <NoSlideout ref={no}>
              <No />
            </NoSlideout>
          </NoWrapper>
          <WhereWrapper>
            <WhereSlideout ref={where}>
              <Where />
            </WhereSlideout>
          </WhereWrapper>
        </LogoWrapper>
      </CenterWrapper>
    </PreloaderWrapper>
  );
};

export default Preloader;
