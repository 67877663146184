import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled(motion.div)`
  width: 100%;
  padding: 5%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.dark};
  ${mediaDesktop(css`
    position: static;
    padding: 0;
    margin: 0 3.3%;
    width: auto;
    transform: translateX(0);
  `)};
`;
