import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  cursor: pointer;
  width: 60%;
  ${mediaTablet(css`
    width: 30%;
  `)};
  ${mediaTabletAndPortrait(css`
    width: 50%;
  `)};
  ${mediaDesktop(css`
    width: 21.5%;
  `)};
`;
