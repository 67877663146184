import ImageLink from 'components/FilmPage/FilmDescription/ImageLink';
import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export const ImageLink1 = styled(ImageLink)`
  margin: -5vh 0 0 50%;
  transform: translateX(-50%);
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    left: ${metadataLeft};
    top: 240vh;
    transform: translateX(0);
  `)}
`;
