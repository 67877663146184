import styled, { css } from 'styled-components';
import { mediaDesktop, mediaTablet } from 'utils/responsive';
import { NexaBlack } from 'utils/styles/typography';

export default styled.div`
  ${NexaBlack};
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 5.33vw;
  line-height: 7.88vw;
  ${mediaTablet(css`
    font-size: 3.5vw;
    line-height: 5.743vw;
  `)}
  ${mediaTablet(css`
    font-size: 4.75vw;
    line-height: 7vw;
  `)}
  ${mediaDesktop(css`
    font-size: 2vw;
    line-height: 3vw;
    font-size: max(2vw, 30px);
    line-height: max(3vw, 44.35px);
  `)}
`;
