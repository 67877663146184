import styled from 'styled-components';

export default styled.div`
  position: relative;
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  &:hover {
    * {
      color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;
