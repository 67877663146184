import React from 'react';
import theme from 'utils/theme';

const Where = () => (
  <svg
    width="93"
    height="27"
    viewBox="0 0 93 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3076 10.6678H22.8026V0.862305H16.5896H16.2187H9.72753L4.95186 17.2668H4.90551L1.56717 6.29948C1.38171 9.59897 1.05714 12.9449 0.778949 16.2444C0.639852 17.685 0.361669 19.0792 0.0371094 20.4733L1.799 26.2823H8.52202L16.2651 2.0241V26.2823H22.849V15.8726H32.354V26.2823H38.9379V0.862305H32.354V10.6678H32.3076Z"
      fill={theme.colors.black}
    />
    <path
      d="M41.21 26.2823H55.6297V20.7057H47.7939V16.2909H55.2588V10.7143H47.7939V6.4389H55.6297V0.862305H41.21V26.2823Z"
      fill={theme.colors.black}
    />
    <path
      d="M93.0001 6.4389V0.862305H78.5804V26.0035L70.8373 15.8726C74.4539 15.222 76.4012 12.2014 76.4012 8.66954C76.4012 3.23236 72.5992 0.862305 67.5917 0.862305H57.7158V26.2823H64.2997V16.5232H64.3461L70.5591 26.2823H78.534H78.7659H92.9538V20.7057H85.118V16.3374H92.5365V10.7608H85.118V6.53184H93.0001V6.4389ZM64.9489 12.2479H64.2997V5.92772H64.9489C67.0817 5.92772 69.5391 6.34595 69.5391 9.08778C69.4927 11.8296 67.0817 12.2479 64.9489 12.2479Z"
      fill={theme.colors.black}
    />
  </svg>
);

export default Where;
