import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.div`
  --gap: 7.29vw;
  overflow: hidden;
  display: inline-flex;
  flex-wrap: nowrap;
  position: relative;
  width: auto;
  left: 7vw;
  cursor: grab;
  ${mediaDesktop(`
    left: 25vw;
  `)};
`;
