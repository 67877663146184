import styled from 'styled-components';
import { Arapey } from 'utils/styles/typography';

export const LabelWrapper = styled.div`
  overflow: hidden;
`;

export const LabelSlideOut = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-100%);
  opacity: 0;
`;

export const LabelText = styled.div`
  ${Arapey};
  font-style: italic;
  font-size: 2.5vh;
`;

export const LabelLine = styled.span`
  height: 2px;
  margin: 0 1rem;
  width: 5.7vw;
  background-color: ${({ light, theme }) =>
    light ? theme.colors.primary.light : theme.colors.primary.dark};
`;
