import styled, { css } from 'styled-components';
import { mediaMobile } from 'utils/responsive';

export default styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  @media only screen and (min-device-width: 400px) {
    transform: translate(-50%, -50%) scale(1);
  }
  @media only screen and (min-device-width: 1024px) {
    transform: translate(-50%, -50%) scale(1.25);
  }
`;
