import PlayButton from 'components/FilmPage/TrailerSection/PlayButton';
import TrailerSectionWrapper from 'components/FilmPage/TrailerSection/styled/TrailerSectionWrapper';
import VideoPlayer from 'components/FilmPage/TrailerSection/styled/VideoPlayer';
import React, { useRef } from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';

interface Props {
  data: FilmPageType;
  commons: CommonsType;
}

const TrailerSection = ({ data, commons }: Props) => {
  const video = useRef(null);
  const playVideo = async () => {
    await video.current.play();
    video.current.controls = true;
  };

  return (
    <TrailerSectionWrapper>
      <VideoPlayer>
        <PlayButton onClick={playVideo} copy={commons.playTrailer} />
        <video
          ref={video}
          src={data.media.trailer.url}
          autoPlay={false}
          playsInline
          controlsList="nodownload"
          poster={data.media.trailer.cover.url}
        />
      </VideoPlayer>
    </TrailerSectionWrapper>
  );
};

export default TrailerSection;
