import Quote from 'components/FilmPage/FilmDescription/Quote';
import { descriptionLeft } from 'components/FilmPage/FilmDescription/styled';
import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export const Quote1 = styled(Quote)`
  font-size: 9vw;
  line-height: 8vw;
  ${mediaDesktop(`
  position: absolute;
    margin: 0;
    top: 220vh;
    font-size: 6.4vh;
    line-height: 6.08vh;
    left: ${descriptionLeft};
  `)};
`;

export const Quote2 = styled(Quote)`
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    bottom: 0;
    left: ${metadataLeft};
  `)};
`;
