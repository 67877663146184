import styled from 'styled-components';
import { useVh } from 'utils/styles/mixins';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 999;
  color: ${({ theme }) => theme.colors.primary.light};
`;
