import React from 'react';

const Line = ({ stroke = 'black' }) => (
  <svg
    width="100%"
    height="2"
    fill="none"
    viewBox="0 0 111 2"
    preserveAspectRatio={'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1H111" stroke={stroke} strokeWidth="2" />
  </svg>
);

export default Line;
