import styled from 'styled-components';
import { useVh } from 'utils/styles/mixins';

export const OpenedWrapper = styled.header`
  width: 100%;
  height: ${useVh(100)};
  position: absolute;
  top: 0;
  color: ${({ theme }) => theme.colors.primary.light};
  z-index: 2;
`;

export const CommonWrapper = styled.div`
  width: 100%;
`;

export const FilmTitleWrapper = styled(CommonWrapper)`
  margin: 6% 0;
`;

export const OpenedContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 8%;
`;
