import { useContext, useEffect, useState } from 'react';

import { FilmPageContext } from 'context/FilmPageContext';
import { isIOSversion } from 'utils/platform';

const useElementOnScreen = options => {
  const [isElement, setIsElement] = useState<boolean>(false);
  const { state } = useContext(FilmPageContext);

  const callbackFunction = entries => {
    const [entry] = entries;
    setIsElement(entry.isIntersecting);
  };

  useEffect(() => {
    const element = document.getElementById(
      `header-content${state.currentFilm}`
    );
    let observer = null;

    if (!isIOSversion(13)) {
      observer = new IntersectionObserver(callbackFunction, options);
    }

    if (element && !isIOSversion(13)) {
      observer.observe(element);
    }

    return () => {
      if (element && !isIOSversion(13)) observer.unobserve(element);
    };
  }, [options]);

  return [isElement];
};

export default useElementOnScreen;
