import { motion } from 'framer-motion';
import styled from 'styled-components';

export default styled(motion.div)`
  height: 100%;
  width: 100%;
  svg {
    transform: translateY(-100%);
  }
`;
