import { DescriptionTextStyle } from 'components/FilmPage/FilmDescription/styled';
import styled from 'styled-components';
import { Arapey, NexaBlack } from 'utils/styles/typography';

export const DescriptionBold = styled.p`
  ${DescriptionTextStyle};
  ${NexaBlack};
  margin-top: 0;
`;

export const DescriptionItalic = styled.p`
  ${DescriptionTextStyle};
  ${Arapey};
  font-style: italic;
  margin-bottom: 0;
`;
