import { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export const descriptionLeft = '58vw';

export const DescriptionTextStyle = css`
  font-size: 5.6vw;
  line-height: 8.27vw;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary.dark};
  ${mediaTablet(css`
    font-size: 3.5vw;
    line-height: 5.16875vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 5.6vw;
    line-height: 8.27vw;
  `)}
  ${mediaDesktop(css`
    font-size: 2vw;
    line-height: 3vw;
    font-size: max(2vw, 30px);
    line-height: max(3vw, 44.35px);
  `)}
`;
