import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.div`
  margin-left: ${metadataLeft};
  margin-top: 6vh;
  div {
    margin-bottom: 6vh;
  }
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    left: ${metadataLeft};
    top: 18.67vh;
    div:first-child {
      margin-bottom: 39.5vh;
    }
    div:nth-child(2) {
      margin-bottom: 6.83vh;
    }
  `)}

  p {
    max-width: 30vw;
  }
`;
