import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export const AuthorsWrapper = styled.div`
  position: absolute;
  bottom: 35%;
  left: 21%;
  ${mediaDesktop(css`
    bottom: 27%;
    left: 58%;

    display: flex;
    flex-direction: column;
  `)}
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.primary.light}!important;
`;

export const Author = styled.div`
  margin-top: 4vh;
`;
