import SlideOut from 'components/FilmPage/AwardsPressSection/HoverLine/styled/SlideOut';
import React from 'react';
import { easeOutQuad } from 'utils/ease';
import HoverLineWrapper from './styled/HoverLineWrapper';

const transition = {
  duration: 0.5,
  ease: easeOutQuad,
};

const variants = {
  hidden: {
    x: '-101%',
    transition,
  },
  hover: {
    x: 0,
    transition,
  },
};

interface Props {
  isHover: boolean;
}

const HoverLine = ({ isHover }: Props) => {
  return (
    <HoverLineWrapper>
      <SlideOut
        variants={variants}
        animate={isHover ? 'hover' : 'hidden'}
        transistion={transition}
      >
        <svg
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 100 2"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M0 1H100 Z" stroke="currentColor" strokeWidth="2" />
        </svg>
      </SlideOut>
    </HoverLineWrapper>
  );
};

export default HoverLine;
