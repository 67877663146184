import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';
import { NexaBlack } from 'utils/styles/typography';

export default styled.h5`
  ${NexaBlack};
  color: ${({ theme }) => theme.colors.primary.dark};
  margin: 5vh 0 1vh 0;
  font-size: 5vw;
  ${mediaTablet(css`
    margin: 3vh 0 0.5vh 0;
    font-size: 2vw;
  `)};
  ${mediaTabletAndPortrait(css`
    margin: 3vh 0 0.75vh 0;
    font-size: 3.5vw;
  `)};
  ${mediaDesktop(css`
    margin: 7.257vh 0 1.3vh 0;
    font-size: 1vw;
    font-size: max(1vw, 20px);
  `)};
`;
