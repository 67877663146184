import FooterLink, {
  FooterLinkPlaceholder,
} from 'components/FilmPage/AwardsPressSection/Slider/SliderItem/styled/FooterLink';
import SectionWrapper from 'components/FilmPage/AwardsPressSection/Slider/SliderItem/styled/SectionWrapper';
import SliderImage from 'components/FilmPage/AwardsPressSection/Slider/SliderItem/styled/SliderImage';
import SliderItemWrapper from 'components/FilmPage/AwardsPressSection/Slider/SliderItem/styled/SliderItemWrapper';
import SliderText from 'components/FilmPage/AwardsPressSection/Slider/SliderItem/styled/SliderText';
import React from 'react';
import { AwardsPressType } from 'types/filmPage';
import Image from 'next/image';

interface Props {
  data: AwardsPressType;
}

const SliderItem = ({ data }: Props) => {
  return (
    <SliderItemWrapper>
      <SectionWrapper>
        <SliderImage>
          <Image
            src={data.image.url}
            quality={100}
            layout={'fill'}
            priority={true}
          />
        </SliderImage>
        <SliderText>{data.main}</SliderText>
      </SectionWrapper>
      <SectionWrapper>
        {data.url ? (
          <FooterLink href={data.url}>
            {data.footerText.toUpperCase()}
          </FooterLink>
        ) : (
          <FooterLinkPlaceholder>
            {data.footerText.toUpperCase()}
          </FooterLinkPlaceholder>
        )}
      </SectionWrapper>
    </SliderItemWrapper>
  );
};

export default React.memo(SliderItem);
