import styled, { css } from 'styled-components';
import { mediaDesktop, mediaTablet } from 'utils/responsive';

export default styled.span`
  display: flex;
  align-items: center;
  width: 14.8vw;
  margin-right: 4vw;
  ${mediaTablet(css`
    width: 13vw;
    margin-right: 3vw;
  `)}
  ${mediaDesktop(css`
    width: 4vw;
    width: max(4vw, 85px);
    margin-right: 1vw;
    margin-right: max(1vw, 15px);
  `)}
`;
