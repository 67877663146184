import styled, { css } from 'styled-components';
import { mediaTablet, mediaTabletAndPortrait } from 'utils/responsive';
import { NexaBlack } from 'utils/styles/typography';

export default styled.h2`
  ${NexaBlack};
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 15vw;
  line-height: 15vw;
  margin: 0;
  position: relative;
  left: 7vw;
  ${mediaTablet(css`
    font-size: 10vw;
    line-height: 10vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 13vw;
    line-height: 13vw;
  `)}
`;
