import {
  StyledLeftImage,
  StyledRightImage,
} from 'components/FilmPage/FilmDescription/Films/NineDays/styled/Images';
import {
  Quote1,
  Quote2,
} from 'components/FilmPage/FilmDescription/Films/NineDays/styled/Quotes';
import QuoteWrapper from 'components/FilmPage/FilmDescription/styled/QuoteWrapper';
import React from 'react';
import { FilmPageType } from 'types/filmPage';

interface Props {
  page: FilmPageType;
}

const NineDays = ({ page }: Props) => {
  return (
    <>
      <StyledLeftImage
        src={page.media.body[0].source.url}
        width={590}
        height={899}
        intro={'roll-down'}
      />
      <QuoteWrapper>
        {page.quotes.map((quote, index) =>
          index % 2 === 0 ? (
            <Quote1 key={quote.author} data={quote} />
          ) : (
            <Quote2 key={quote.author} data={quote} />
          )
        )}
      </QuoteWrapper>
      <StyledRightImage
        src={page.media.body[1].source.url}
        width={981}
        height={648}
        intro={'roll-right'}
      />
    </>
  );
};

export default NineDays;
