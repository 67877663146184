import styled from 'styled-components';

export default styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  video {
    width: 100%;
    object-fit: cover;
    outline: none;
  }
`;
