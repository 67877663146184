import { descriptionTop } from 'components/FilmPage/FilmDescription/CommonLayout/styled';
import { descriptionLeft } from 'components/FilmPage/FilmDescription/styled';
import { metadataLeft } from 'components/FilmPage/styled';
import styled from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.div`
  margin: 4vh 0 8vh ${metadataLeft};
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    left: ${descriptionLeft};
    top: ${descriptionTop};
  `)}
`;
