import styled, { css } from 'styled-components';
import { mediaDesktop } from 'utils/responsive';

export default styled.section`
  ${mediaDesktop(css`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  `)}
`;
