import FilmImage from 'components/FilmPage/FilmDescription/FilmImage';
import styled from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled(FilmImage)`
  height: auto;
  transform: translateX(-25%);
  width: 150%;
  ${mediaTablet(`
    margin-left: 50%;
    width: 70%;
    transform: translateX(-50%);
  `)}
  ${mediaTabletAndPortrait(`
    width: 100%;
    margin-left: 0;
    transform: translateX(0);
  `)}
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    left: 41.9%;
    width: 51%;
    transform: translateX(0);
  `)}
`;
