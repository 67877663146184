import styled, { css } from 'styled-components';
import { mediaTablet } from 'utils/responsive';

export const HeadImageWidth = {
  mobile: '92%',
  tablet: '85%',
};

export default styled.div`
  width: ${HeadImageWidth.mobile};
  height: auto;
  pointer-events: none;
  user-select: none;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  align-self: ${({ index }) => (index % 2 === 0 ? 'flex-end' : 'flex-start')};
  ${mediaTablet(css`
    width: 85%;
  `)};
`;
