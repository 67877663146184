import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
    transform-origin: center center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
