import styled from 'styled-components';

export default styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 2vh;
  & > *:first-child {
    margin-right: 1vw;
  }
`;
