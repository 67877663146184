import CommonLayout from 'components/FilmPage/FilmDescription/CommonLayout';
import styled, { css } from 'styled-components';
import { mediaDesktop, mediaTablet } from 'utils/responsive';

export default styled(CommonLayout)`
  ${({variant}) => {
    switch(variant) {
      case 'large':
         return css`
            ${mediaDesktop(`height: 240vh;`)}
         `;
     default:
        return mediaDesktop(`height: 80vw;`);

}
}}
  ${mediaDesktop(`
  `)}
`;
