import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { mediaTablet, mediaTabletAndPortrait } from 'utils/responsive';

export default styled(motion.div)`
  width: 8vw;
  height: 8vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 999;
  ${mediaTablet(css`
    height: 4.16vw;
    width: 4.16vw;
  `)};
  ${mediaTabletAndPortrait(css`
    width: 8vw;
    height: 8vw;
  `)};
`;
