import FilmPage from 'components/FilmPage';
import MouseCursor from 'components/MouseCursor';
import Preloader from 'components/Preloader';
import HR from 'components/HR';
import { FilmWrapper, FooterWrapper } from 'containers/styled';
import { FilmPageContext } from 'context/FilmPageContext';
import { Router } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GetStaticProps } from 'next';
import { AboutPageType } from 'types/aboutPage';
import { BlogPageType } from 'types/blogPage';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import { MenuItemsType } from 'types/menuItems';
import Head, { HeadProps } from 'u9/components/Head';
import Footer from 'components/Footer';
import { cmsApiClient } from 'utils/api/cms';
import { goToTop } from 'utils/dom';

import {
  aboutPageQuery,
  commonsQuery,
  errorsQuery,
  filmsQuery,
  indexQuery,
  menuQuery,
  newsPageQuery,
  pressPageQuery,
} from 'utils/gql';
import useDesktopMode from 'utils/hooks/useDesktopMode';
import { motion } from 'framer-motion';
import { ACTIONS, CATEGORIES, LABEL, Tracking } from 'utils/tracking';
import useElementOnScreen from 'utils/hooks/useElementOnScreen';
import Menu from 'components/Menu';

export const getStaticProps: GetStaticProps = async context =>
  Promise.all([
    cmsApiClient.query({ query: indexQuery(context.locale) }),
    cmsApiClient.query({ query: errorsQuery(context.locale) }),
    cmsApiClient.query({ query: filmsQuery(context.locale) }),
    cmsApiClient.query({ query: aboutPageQuery(context.locale) }),
    cmsApiClient.query({ query: pressPageQuery(context.locale) }),
    cmsApiClient.query({ query: newsPageQuery(context.locale) }),
    cmsApiClient.query({ query: menuQuery(context.locale) }),
    cmsApiClient.query({ query: commonsQuery(context.locale) }),
  ]).then(
    ([
      pageResults,
      errorsResults,
      filmPagesResult,
      aboutPageResult,
      pressPageResult,
      newsPageResult,
      menuResult,
      commonResult,
    ]) => {
      return {
        props: {
          page: pageResults.data.indexPages[0],
          errors: errorsResults.data.errorPages,
          filmPages: [...filmPagesResult.data.moviePages].sort(
            (a, b) => a.index - b.index
          ),
          aboutPage: aboutPageResult.data.aboutPages[0],
          pressPage: pressPageResult.data.pressPages[0],
          newsPage: newsPageResult.data.newsPages[0],
          menu: menuResult.data.menuItems[0],
          commons: commonResult.data.commons[0],
        },
      };
    }
  );

export type IndexPageProps = {
  head: HeadProps;
  title: string;
};

export type IndexProps = {
  page: IndexPageProps;
  filmPages: Array<FilmPageType>;
  aboutPage: AboutPageType;
  pressPage: BlogPageType;
  newsPage: BlogPageType;
  menu: MenuItemsType;
  commons: CommonsType;
  router: Router;
};

const LandingPage = (props: IndexProps): JSX.Element => {
  const lang = props.router.locale;
  const headProps = { ...props.page.head, lang };
  const { state, setState } = useContext(FilmPageContext);
  const [mounted, setMounted] = useState(false);
  const { isDesktopMode } = useDesktopMode();

  const [isElement] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const switchPage = (index: number) => {
    setState({
      isHovered: index,
      currentFilm: index,
      isOpen: true,
      menuOpen: false,
    });
    goToTop();
  };

  const findFilmByHash = (): number =>
    props.filmPages.findIndex(page => location.hash === `#${page.urlTitle}`);

  const managePages = useCallback(() => {
    const currentFilm = findFilmByHash();
    if (currentFilm === -1) {
      setState({
        isHovered: -1,
        currentFilm: -1,
        isOpen: false,
        menuOpen: false,
      });
      goToTop();
    } else if (currentFilm !== state.currentFilm) {
      switchPage(currentFilm);
    }
  }, [state.currentFilm]);

  useEffect(() => {
    setState({
      filmLinks: props.filmPages.map(film => film.linkTitle),
    });
    const currentFilm = findFilmByHash();
    if (currentFilm !== -1) {
      switchPage(currentFilm);
    }
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      if (state.currentFilm === -1) {
        location.hash = 'home';
      } else {
        location.hash = props.filmPages[state.currentFilm].urlTitle;
      }
    }
  }, [state.currentFilm, mounted]);

  useEffect(() => {
    if (process.browser && mounted) {
      window.addEventListener('hashchange', managePages);
      return () => {
        window.removeEventListener('hashchange', managePages);
      };
    }
    return () => {};
  }, [managePages, mounted]);

  useEffect(() => {
    Tracking.event({
      category: CATEGORIES.PageLanding,
      action: ACTIONS.PageLanding,
      label: LABEL.PageLanding,
    });
  }, []);

  return (
    <motion.div>
      <Head {...headProps} />
      {!state.preloaderExited && <Preloader data={props.filmPages} />}
      <Menu light={state.isOpen && isElement} items={props.menu.items} />
      {isDesktopMode && <MouseCursor />}
      <FilmWrapper hasMargin={!state.isOpen}>
        {props.filmPages.map((film, index) => (
          <FilmPage
            key={film.title}
            page={film}
            commons={props.commons}
            index={index}
          />
        ))}
      </FilmWrapper>
      <HR />
      <FooterWrapper>
        <Footer copy={props.commons.footer} line={true} />
      </FooterWrapper>
    </motion.div>
  );
};

export default LandingPage;
