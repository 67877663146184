import React from 'react';
import theme from 'utils/theme';

const No = () => (
  <svg
    width="72"
    height="28"
    viewBox="0 0 72 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.637 16.6715H19.5906L7.53556 1.10352H0.905273V26.57H7.53556V10.9555H7.58193L19.637 26.57H26.2673V1.10352H19.637V16.6715Z"
      fill={theme.colors.primary.light}
    />
    <path
      d="M19.637 16.6715H19.5906L7.53556 1.10352H0.905273V26.57H7.53556V10.9555H7.58193L19.637 26.57H26.2673V1.10352H19.637V16.6715Z"
      fill={theme.colors.primary.light}
    />
    <path
      d="M41.29 0.266602C34.0106 0.266602 27.2412 5.65731 27.2412 13.3716C27.2412 21.5971 33.1297 27.406 41.29 27.406C49.4504 27.406 55.3388 21.5971 55.3388 13.3716C55.3388 5.65731 48.6158 0.266602 41.29 0.266602ZM41.29 20.9929C37.3489 20.9929 34.1033 17.7864 34.1033 13.4181C34.1033 9.9327 37.3489 6.72616 41.29 6.72616C45.2311 6.72616 48.4767 9.9327 48.4767 13.4181C48.4767 17.7864 45.2311 20.9929 41.29 20.9929Z"
      fill={theme.colors.primary.light}
    />
    <path
      d="M70.222 1.52119L65.0291 17.5539H64.9827L60.5779 1.14941H53.7158L61.2734 26.5694H67.9501L71.5666 15.9274C71.613 14.8585 71.6593 13.7897 71.7057 12.7673C71.7984 8.8637 71.2421 5.14597 70.222 1.52119Z"
      fill={theme.colors.primary.light}
    />
    <path
      d="M70.222 1.52119L65.0291 17.5539H64.9827L60.5779 1.14941H53.7158L61.2734 26.5694H67.9501L71.5666 15.9274C71.613 14.8585 71.6593 13.7897 71.7057 12.7673C71.7984 8.8637 71.2421 5.14597 70.222 1.52119Z"
      fill={theme.colors.primary.light}
    />
  </svg>
);

export default No;
