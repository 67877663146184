import styled, { css } from 'styled-components';
import { mediaTablet } from 'utils/responsive';

export default styled.div`
  height: 50vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 2.5vw;
  ${mediaTablet(css`
    top: 3.2vw;
  `)}
  div {
    width: 100%;
    height: 100%;
    transform-origin: center center;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
