import Quote from 'components/FilmPage/FilmDescription/Quote';
import { descriptionLeft } from 'components/FilmPage/FilmDescription/styled';
import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export const Quote1 = styled(Quote)`
  font-size: 12vw;
  line-height: 11vw;
  ${mediaTablet(css`
    font-size: 8vw;
    line-height: 7vw;
  `)}
  ${mediaTabletAndPortrait(css`
    font-size: 12vw;
    line-height: 11vw;
  `)}
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    top: 182vh;
    font-size: 9.6vh;
    line-height: 8.53vh;
    left: ${descriptionLeft};
  `)};
`;

export const Quote2 = styled(Quote)`
  ${mediaDesktop(`
    position: absolute;
    margin: 0;
    bottom: 0;
    left: ${descriptionLeft};
  `)};
`;
