import Link from 'components/FilmPage/LinkSection/Link';
import LinkSectionWrapper from 'components/FilmPage/LinkSection/styled/LinkSectionWrapper';
import { FilmPageContext } from 'context/FilmPageContext';
import React, { useContext } from 'react';
import { goToTop } from 'utils/dom';
import { getWithWrap, wrap } from 'utils/func';

interface Props {
  index: number;
}

const LinkSection = ({ index }: Props) => {
  const { state, setState } = useContext(FilmPageContext);

  const switchPage = (index: number) => {
    const wrapped = wrap(index, state.filmLinks.length);
    setState({ isHovered: wrapped, currentFilm: wrapped });
    goToTop();
  };

  return (
    <LinkSectionWrapper>
      <Link
        text={getWithWrap(index - 1, state.filmLinks)}
        onClick={() => switchPage(index - 1)}
      />
      <Link
        right
        text={getWithWrap(index + 1, state.filmLinks)}
        onClick={() => switchPage(index + 1)}
      />
    </LinkSectionWrapper>
  );
};

export default LinkSection;
