import Cursor from 'components/MouseCursor/styled/Cursor';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const MouseCursor = () => {
  const cursor = useRef();
  const xSet = useRef<Function>(() => {});
  const ySet = useRef<Function>(() => {});

  const setMousePos = (x: number, y: number) => {
    xSet.current(x);
    ySet.current(y);
  };

  const handleMouse = e => setMousePos(e.x, e.y);

  useEffect(() => {
    if (process.browser) {
      xSet.current = gsap.quickSetter(cursor.current, 'x', 'px');
      ySet.current = gsap.quickSetter(cursor.current, 'y', 'px');
      document.addEventListener('mousemove', handleMouse);
      return () => {
        document.removeEventListener('mousemove', handleMouse);
      };
    }
    return () => {};
  }, []);

  return <Cursor ref={cursor} />;
};

export default MouseCursor;
