import styled from 'styled-components';

export default styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  object-fit: cover;
`;
