import PlayButtonWrapper from 'components/FilmPage/TrailerSection/PlayButton/styled/PlayButtonWrapper';
import CallBackFunc from 'interface/callbackFunc';
import React, { useState } from 'react';
import theme from 'utils/theme';
import xss from 'xss';
import PlayTrailer from './styled/PlayTrailer';

interface Props {
  onClick?: CallBackFunc<void, void>;
  copy: string;
}

const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const PlayButton = ({ onClick, copy }: Props) => {
  const [clicked, setClicked] = useState(false);

  const onButtonClick = () => {
    onClick();
    setClicked(true);
  };

  return (
    <PlayButtonWrapper
      role={'button'}
      aria-label="play-trailer"
      onClick={onButtonClick}
      variants={variants}
      initial={'visible'}
      animate={clicked ? 'hidden' : 'visible'}
    >
      <svg width="100%" viewBox="0 0 100 100">
        <g shapeRendering="geometricPrecision">
          <circle fill="white" cx="50" cy="50" r="50" />
          <polygon
            points="0,0 0,86.60254 74.99999,43.30127"
            fill={theme.colors.primary.dark}
            transform={'translate(25, 6.69873)'}
          />
        </g>
      </svg>
      <PlayTrailer
        dangerouslySetInnerHTML={{ __html: xss(copy) }}
        variants={variants}
      />
    </PlayButtonWrapper>
  );
};

export default PlayButton;
