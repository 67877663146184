import React from 'react';
import styled, { css } from 'styled-components';
import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  position: relative;
  height: 4vh;
  min-height: 2rem;
  ${mediaTablet(css`
    height: 5vh;
    min-height: 3rem;
  `)}
  ${mediaTabletAndPortrait(css`
    height: 4vh;
    min-height: 2rem;
  `)}
  ${mediaDesktop(css`
    height: 4vh;
    min-height: 0;
  `)}
  width: 100%;
  filter: brightness(0);
  div {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
  }
`;
