import styled from 'styled-components';
import { breakpointDesktop } from 'utils/responsive/vars';

export default styled.div`
  position: absolute;
  left: ${({ index }) => (index % 2 === 0 ? '10%' : '30%')};
  @media (min-width: ${breakpointDesktop}px) {
    left: ${({ index }) => (index % 2 === 0 ? '21%' : '50%')};
  }
  &.top {
    bottom: 0;
    transform: translateY(50%);
  }
  &.bottom {
    top: 0;
    transform: translateY(-50%);
  }
  user-select: none;
  overflow: hidden;
`;
