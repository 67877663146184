import styled from 'styled-components';

export default styled.div`
  width: 90%;
  position: relative;
  left: 50%;
  margin-top: 5vh;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray3}`};
  div {
    margin-top: 2vh;
    margin-top: max(2vh, 3vw);
  }
`;
