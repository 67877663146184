import DoubleLineMetadata from 'components/FilmPage/DoubleLineMetadata';
import Hitbox from 'components/FilmPage/FilmPageHeadDesktop/styled/Hitbox';
import { createTitle } from 'components/FilmPage/FilmPageHeadDesktop/utils';
import {
  CommonWrapper,
  FilmTitleWrapper,
  OpenedContent,
  OpenedWrapper,
} from 'components/FilmPage/FilmPageHeadMobile/styled';
import FeaturedAwardsMobile from 'components/FilmPage/FilmPageHeadMobile/styled/FeaturedAwardsMobile';
import FilmNumber from 'components/FilmPage/FilmPageHeadMobile/styled/FilmNumber';
import FilmTitle from 'components/FilmPage/FilmPageHeadMobile/styled/FilmTitle';
import FilmTitleOpened from 'components/FilmPage/FilmPageHeadMobile/styled/FilmTitleOpened';
import HeadImage, {
  HeadImageWidth,
} from 'components/FilmPage/FilmPageHeadMobile/styled/HeadImage';
import MainContainer from 'components/FilmPage/FilmPageHeadMobile/styled/MainContainer';
import TitleWrapper from 'components/FilmPage/FilmPageHeadMobile/styled/TitleWrapper';
import HeaderLogo from 'components/FilmPage/HeaderLogo';
import useOpenClose from 'components/FilmPage/hooks/useOpenClose';
import SlideOut from 'components/SlideOut';
import { FilmPageContext } from 'context/FilmPageContext';
import gsap from 'gsap';
import Image from 'next/image';
import React, { useContext, useRef } from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import { goToTop } from 'utils/dom';
import { breakpoints } from 'utils/responsive';
import { parseNewlines } from 'utils/stringUtils';
import { useVh } from 'utils/styles/mixins';

interface Props {
  page: FilmPageType;
  commons: CommonsType;
  index: number;
}

const FilmPageHeadMobile = ({ page, commons, index }: Props) => {
  const { state, setState } = useContext(FilmPageContext);
  const wrapper = useRef(null);
  const background = useRef(null);

  const openPage = () => {
    if (!state.isOpen) {
      gsap.set(wrapper.current, {
        height: wrapper.current.getBoundingClientRect().height,
      });
      gsap.set(background.current, {
        top: background.current.getBoundingClientRect().top,
        position: 'fixed',
      });
      gsap.to(background.current, {
        width: '100%',
        height: useVh(100),
        top: 0,
        left: 0,
        onComplete: () => {
          gsap.set(wrapper.current, {
            height: useVh(100),
          });
          gsap.set(background.current, {
            position: 'absolute',
          });
          goToTop();
          setState({ isOpen: true });
        },
      });
    }
  };

  const closePage = () => {
    gsap.set(wrapper.current, {
      height: 'auto',
    });
    gsap.set(background.current, {
      position: 'relative',
      top: 0,
      width:
        innerWidth > breakpoints.tablet
          ? HeadImageWidth.tablet
          : HeadImageWidth.mobile,
      height: 'auto',
    });
  };

  const openPageNoAnimation = () => {
    gsap.set(wrapper.current, {
      height: useVh(100),
    });
    gsap.set(background.current, {
      position: 'absolute',
      width: '100%',
      height: useVh(100),
      top: 0,
      left: 0,
    });
  };

  useOpenClose(openPage, openPageNoAnimation, closePage, index);

  const onLogoClick = () => {
    location.hash = 'home';
  };

  return (
    <MainContainer
      onClick={() => setState({ isHovered: index, currentFilm: index })}
      ref={wrapper}
      role="button"
      aria-label="View film details"
    >
      {!state.isOpen && (
        <TitleWrapper index={index}>
          <FilmNumber>{('0' + (index + 1)).slice(-2)}</FilmNumber>
          <FilmTitle dangerouslySetInnerHTML={createTitle(page.title)} />
        </TitleWrapper>
      )}
      {state.isOpen && (
        <OpenedWrapper>
          <HeaderLogo onClick={onLogoClick} />
          <OpenedContent>
            {page.featuredAwards.length > 0 && (
              <FeaturedAwardsMobile
                images={page.featuredAwards}
                label={commons.featuredAwards}
              />
            )}
            {page.authors.length > 1 &&
              page.authors.slice(1).map((author, index) => (
                <CommonWrapper key={index}>
                  <SlideOut>
                    <DoubleLineMetadata>
                      {parseNewlines(author.name).toUpperCase()}
                      {author.title}
                    </DoubleLineMetadata>
                  </SlideOut>
                </CommonWrapper>
              ))}
            <FilmTitleWrapper>
              <SlideOut>
                <FilmTitleOpened
                  dangerouslySetInnerHTML={createTitle(page.title)}
                />
              </SlideOut>
            </FilmTitleWrapper>
            <CommonWrapper>
              <SlideOut>
                <DoubleLineMetadata>
                  {parseNewlines(page.authors[0].name).toUpperCase()}
                  {page.authors[0].title}
                </DoubleLineMetadata>
              </SlideOut>
            </CommonWrapper>
          </OpenedContent>
        </OpenedWrapper>
      )}
      <HeadImage index={index} ref={background}>
        <Image
          src={page.media.headMobile.source.url}
          layout={'responsive'}
          quality={100}
          width={1920}
          height={868}
          priority
        />
      </HeadImage>
    </MainContainer>
  );
};

export default FilmPageHeadMobile;
