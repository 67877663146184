import styled from 'styled-components';

export default styled.div`
  border-top: #c4c4c4 solid 2px;
  width: auto;
  margin-top: 10vh;
  left: 25vw;
  position: relative;
  color: ${({ theme }) => theme.colors.primary.dark};
`;
