import FilmDescription from 'components/FilmPage/FilmDescription/CommonLayout/FilmDescription';
import AboutText from 'components/FilmPage/FilmDescription/CommonLayout/styled/AboutText';
import MainWrapper from 'components/FilmPage/FilmDescription/CommonLayout/styled/MainWrapper';
import MetadataWrapper from 'components/FilmPage/FilmDescription/CommonLayout/styled/MetadataWrapper';
import React, { ReactNode } from 'react';
import { CommonsType } from 'types/commons';
import { FilmPageType } from 'types/filmPage';
import { parseNewlines } from 'utils/stringUtils';
import { MetadataBold, MetadataItalic } from 'utils/styles/typography';
import xss from 'xss';

interface Props {
  page: FilmPageType;
  commons: CommonsType;
  children?: ReactNode;
  className?: string;
}

const CommonLayout = ({ page, commons, children, className }: Props) => {
  return (
    <MainWrapper className={className}>
      <MetadataWrapper>
        {!!page.release && (
          <div>
            <MetadataBold>{page.release.date.toUpperCase()}</MetadataBold>
            <MetadataItalic>{page.release.text}</MetadataItalic>
          </div>
        )}
        {!!page.distributor && (
          <div>
            <MetadataBold>
              {page.distributor.company.toUpperCase()}
            </MetadataBold>
            <MetadataItalic>{page.distributor.title}</MetadataItalic>
          </div>
        )}
        {!!page.premiere && (
          <div>
            <MetadataBold>
              {parseNewlines(page.premiere.festival).toUpperCase()}
            </MetadataBold>
            <MetadataItalic>{page.premiere.title}</MetadataItalic>
          </div>
        )}
      </MetadataWrapper>
      <AboutText dangerouslySetInnerHTML={{ __html: xss(commons.about) }} />
      {page.description && (
        <FilmDescription
          bold={page.description.bold}
          normal={page.description.normal}
        />
      )}
      {children}
    </MainWrapper>
  );
};

export default CommonLayout;
