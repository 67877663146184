import React from 'react';

interface Props {
  className?: string;
}

const ArrowLong = ({ className }: Props) => (
  <svg
    width="131"
    height="9"
    viewBox="0 0 131 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M121.823 9.00001L131 4.50001L121.823 -4.01159e-07L123.391 3.54939L-1.55149e-07 3.54939L-2.38255e-07 5.45062L123.391 5.45063L121.823 9.00001Z"
      fill="currentColor"
      shapeRendering="geometricPrecision"
    />
  </svg>
);

export default ArrowLong;
