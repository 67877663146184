import styled from 'styled-components';
import { breakpointDesktop } from 'utils/responsive/vars';

export default styled.div`
  position: absolute;
  top: 50%;
  left: ${({ index }) => (index % 2 === 0 ? '10%' : '30%')};
  @media (min-width: ${breakpointDesktop}px) {
    left: ${({ index }) => (index % 2 === 0 ? '21%' : '50%')};
  }
  transform: translateY(-50%);
  z-index: 2;
  height: ${({ size }) => `${size.height * 100}%`};
  width: ${({ size }) => `${size.width * 100}%`};
  cursor: pointer;
`;
