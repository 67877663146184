import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

const Wrapper = styled.div`
  overflow: hidden;
  height: auto;
  position: relative;
`;

const Inner = styled.div`
  transform: translateY(100%);
  position: relative;
`;

const SlideOut = ({ duration = 0.5, ease = 'power1.out', children }) => {
  const el = useRef(null);

  const animation = ref => {
    gsap.to(ref.current, {
      y: 0,
      duration,
      ease,
    });
  };

  useEffect(() => {
    animation(el);
  }, []);

  return (
    <Wrapper>
      <Inner ref={el}>{children}</Inner>
    </Wrapper>
  );
};

export default SlideOut;
